
Eloca.behaviors.oportunidades = function (context) {
    if ($("#kanban", context).length) {
        const ids = $("#kanban", context).data("ids");
        const url = $("#kanban", context).data("url");
        if ($("#kanban", context).data("ids")) {
            $(ids)
                .sortable({
                    connectWith: ".connectedSortable",
                    receive: function (event, ui) {
                        const oportunidadeId = $(ui.item[0]).data("id");
                        const faseId = $(event.target).data("id");

                        const oportunidades = [];
                        $("li", $(event.target)).each(function (index, li) {
                            oportunidades.push({
                                id: $(li).data("id"),
                                ordem: index + 1,
                            });
                        });
                        $.ajax({
                            url: url,
                            type: "put",
                            data: {
                                id: oportunidadeId,
                                fase_id: faseId,
                                oportunidades: oportunidades,
                            },
                            headers: {
                                "X-CSRF-TOKEN": $(
                                    'meta[name="csrf-token"]'
                                ).attr("content"),
                            },
                        });
                        somaOportunidades();
                    },
                })
                .disableSelection();
        }
        somaOportunidades();
        ordenaOportunidades();
    }

    if ($("#minhas-oportunidades", context).length) {
        somaOportunidades();
        ordenaOportunidades();
    }

    $(".js-bloco-chat-interno").attr(
        "style",
        "min-height:" + ($(window).height() - 105).toString() + "px;"
    );

    $(".js-bloco-chat, .js-bloco-midias, .js-linha-chat").height(
        $(window).height() - 105
    );
    $(window).on("resize", function () {
        $(".js-bloco-chat, .js-bloco-midias, .js-linha-chat").height(
            $(window).height() - 105
        );

        $(".js-bloco-chat-interno").attr(
            "style",
            "min-height:" + ($(window).height() - 105).toString() + "px;"
        );
    });

    $(".js-add-oportunidade", context).on("click", function () {
        let faseId = $(this).data("fase-id");
        const modal = $("#modalNovaOportunidade", context);
        if (!faseId) {
            faseId = $("#fase_id option", modal)[0].value;
        }
        $("#fase_id", modal).val(faseId);
    });

    $(".js-autocomplete-cliente", context).each(function () {
        const input = this;
        let novo = true;
        if ($(this).data('novo') == false) {
            novo = false;
        }
        $(this).on("keyup", function () {
            if (this.value.length == 0) {
                $(input)
                    .closest(".form-group")
                    .find(".js-input-autocomplete")
                    .val("");
                getLocacao();
            }
        });

        $(input)
            .autocomplete({
                source: siteUrl("/painel/clientes/busca/autocomplete"),
                minLength: 2,
                select: function select(event, ui) {
                    if (ui.item.noResult && novo) {
                        setNovoCliente(input.value);
                        getLocacao();
                    } else {
                        setTimeout(function () {
                            $(input)
                                .closest(".form-group")
                                .find(".js-input-autocomplete")
                                .val(ui.item.id);
                            $(input).val(ui.item.nome);
                        }, 1);
                        getLocacao(ui.item.id);
                    }
                },
                response: function (event, ui) {
                    if (ui.content.length < 10 && novo) {
                        var noResult = { noResult: true };
                        ui.content.push(noResult);
                    }
                },
            })
            .autocomplete("instance")._renderItem = function (ul, item) {
                $(ul).css({
                    "z-index": 2000,
                    "max-height": "300px",
                    "overflow-y": "auto",
                });
                $(ul).addClass("oportunidades-autocomplete-clientes");

                let html = "";
                if (item.noResult) {
                    html = `<div class="bloco bloco-novo-cliente js-oportunidade-novo-cliente">
                        <span class="novo">Adicionar cliente "${input.value}"</span>
                        </div>`;
                    Eloca.load(html);
                } else {
                    let tipoBloco = "bloco-pf";
                    let nome = item.nome;
                    if (item.pj) {
                        tipoBloco = "bloco-pj";
                        nome = item.nome_fantasia;
                    }

                    html = `<div class="bloco ${tipoBloco}">
                    <span class="nome">${nome}</span>
                    <span class="small contato ${item.pj ? '' : 'd-none'}">Contato: ${item.nome}</span>
                    <span class="email">${item.email}</span>
                    <span class="telefone">${item.telefone1 ? item.telefone1 : ""
                        }</span>
                    </div>`;
                }
                return $("<li>").append(html).appendTo(ul);
            };
    });

    $(".js-cancelar-novo-cliente", context).on("click", function () {
        cancelNovoCliente();
    });

    $(".js-valida-oportunidade", context).on("click", function () {
        let valid = true;
        const configWhatsappAtivo = $(
            "#js-config-whatsapp-ativo",
            context
        ).val();
        const contextForm = $(this).closest(".js-form-oportunidade");
        if (!$("#nome", contextForm).val()) {
            $(" #nome", contextForm).addClass(
                "is-invalid"
            );
            valid = false;
        } else {
            $("#nome", contextForm).removeClass(
                "is-invalid"
            );
        }
        if (
            !$(".js-input-autocomplete", contextForm).val() &&
            !$(this).data("novoCliente")
        ) {
            $(".js-autocomplete-cliente", contextForm).addClass("is-invalid");
            valid = false;
        } else {
            $(".js-autocomplete-cliente", contextForm
            ).removeClass("is-invalid");
        }
        if ($(this).data("novoCliente")) {
            if (!$(" #cliente_nome", contextForm).val()) {
                $(" #cliente_nome", contextForm).addClass(
                    "is-invalid"
                );
                valid = false;
            } else {
                $("#cliente_nome", contextForm
                ).removeClass("is-invalid");
            }

            if (configWhatsappAtivo) {
                const telefone = $("#cliente_telefone", contextForm).val();
                const mascara = $("#cliente_telefone", contextForm).data('mascara');
                let telefoneInvalido = false;
                if (!telefone) {
                    valid = false;
                    telefoneInvalido = true;
                } else if (mascara == 'br' && (telefone.length < 14 || telefone.length > 15)) {
                    valid = false;
                    telefoneInvalido = true;
                } else if (mascara == 'py' && telefone.length != 10) {
                    valid = false;
                    telefoneInvalido = true;
                }
                if (telefoneInvalido) {
                    $("#cliente_telefone", contextForm).addClass("is-invalid");
                } else {
                    $("#cliente_telefone", contextForm).removeClass("is-invalid");
                }
            } else {
                const regex = /\S+@\S+\.\S+/;
                const email = $("#cliente_email", contextForm).val();
                if (!email || !regex.test(email)) {
                    $("#cliente_email", contextForm).addClass("is-invalid");
                    valid = false;
                } else {
                    $("#cliente_email", contextForm).removeClass("is-invalid");
                }
            }
        }

        if (valid) {
            $("#overlayer-loading").addClass("show-overlayer");
            $(this).closest('form').submit();
        }
    });

    $(".js-filtro-oportunidade", context).on("change", function () {


        if (
            $("select[name='status']").val() == 1 ||
            $("select[name='status']").val() == 3
        ) {
            $(".js-div-status-conteudo").remove();
        }

        if (
            $("select[name='tipo']").val() == ""
        ) {
            $(".js-div-tipo-conteudo").remove();
        }

        $("#overlayer-loading").addClass("show-overlayer");
        $(".js-form-filtro-oportunidades", context).submit();
    });

    $(".js-update-fase", context).on("click", function () {
        const link = this;
        $.ajax({
            url: siteUrl("/painel/oportunidades/update-fase"),
            type: "put",
            data: {
                id: $(link).data("oportunidade"),
                fase_id: $(link).data("fase"),
            },
            headers: {
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
            },
            success: function (res) {
                if (!res.error) {
                    $(".js-fase-nome", context).text($(link).text());
                    $(".js-fase-cor", context).css(
                        "background-color",
                        $(link).data("cor")
                    );
                    $(".js-updated-at", context).text(res.updated_at);
                    showToastSuccess(context);
                } else {
                    showToastDanger(context, res.message);
                }
            },
        });
    });
    //precisa tornar essa função em um script global
    function isEmpty(stringVal) {
        if (
            stringVal == "" ||
            stringVal == null ||
            stringVal == "undefined" ||
            stringVal == "NULL" ||
            stringVal == "0"
        ) {
            return true;
        } else {
            return false;
        }
    }

    $(".js-select-status", context).on("click", function () {
        const elemento = this;
        $(".js-status-cor-selected").css(
            "background-color",
            $(elemento).attr("data-cor")
        );
        $(".js-status-nome-selected").html($(elemento).attr("data-nome"));

        $(".status-op-bloco").addClass("d-none");
        $(".status-op").addClass("d-none");
        let statusSelected = $(elemento).attr("data-id");
        let tipoSelected = $(elemento).attr("data-tipo");

        $(".status-op-bloco-" + $(elemento).attr("data-tipo")).removeClass(
            "d-none"
        );

        const dataTipo = $(elemento).attr("data-tipo")
        if (dataTipo != 3 && dataTipo != 4) {
            $(".status-op-" + dataTipo).removeClass("d-none");
        }

        if (statusSelected != "undefined" && statusSelected != "") {
            $(".btn-encerrar").attr("data-status", statusSelected);
            $(".btn-encerrar").attr("data-tipo", tipoSelected);
            $(".btn-encerrar").attr(
                "data-atendimento",
                $(elemento).attr("data-atendimento")
            );
            $(".btn-encerrar").attr(
                "data-perda",
                $(elemento).attr("data-perda")
            );
        }
    });
    $(".js-select-status-tipo", context).on("change", function () {
        $(".status-op").addClass("d-none");
        let tipoSelected = $(".js-update-status").attr("data-tipo");
        let subTipoSelected = "";
        if (tipoSelected == 4) {
            const valor = $(this).find("option:selected").attr("data-tipo-atendimento");
            subTipoSelected = ".status-op-atendimento-" + valor;
            $(subTipoSelected).removeClass("d-none");
            $(".status-op-atendimento-todos").removeClass("d-none");
        } else if (tipoSelected == 3) {
            const valor = $(this).find("option:selected").attr("data-motivo_perda_id");
            subTipoSelected = ".status-op-perda-" + valor;
            $(subTipoSelected).removeClass("d-none");
            $(".status-op-perda-todos").removeClass("d-none");
        } else {
            $(".status-op-" + $(elemento).attr("data-tipo")).removeClass("d-none");
        }
    });
    $(".js-update-status", context).on("click", function () {
        const link = this;
        let dataOportunidade = $(link).attr("data-oportunidade");
        let dataStatus = $(link).attr("data-status");
        let dataTipo = $(link).attr("data-tipo");
        let tipoAtendimento = $("#select-tipo-atendimento").find('option:selected').attr('data-tipo-atendimento');
        let motivo_perda_id = $("#select-motivo-perda-id").find('option:selected').attr('data-motivo_perda_id');

        let atendimento = $(link).attr("data-atendimento");
        let perda = $(link).attr("data-perda");

        let conteudo_valor = document.querySelectorAll(".js-conteudo-valor");
        let camposCustomizados = [];
        if (conteudo_valor.length > 0) {
            for (let conteudo of conteudo_valor) {
                let tipos = conteudo.getAttribute("data-required");
                let subTipos = conteudo.getAttribute("data-obrigatorio");
                tipos = tipos.split(",");
                subTipos = subTipos.split(",");
                for (let tipo of tipos) {
                    if (dataTipo != 3 && dataTipo != 4) {
                        if (dataTipo == tipo && isEmpty(conteudo.value)) {
                            showToastDanger(
                                context,
                                "Por favor preencha todos os campos customizados obrigatórios"
                            );
                            return false;
                        }
                    } else if (dataTipo == 3 && dataTipo == tipo) {
                        const subTipo = subTipos[1];
                        let valorSelecionado = $("#select-motivo-perda-id").find("option:selected").attr("data-motivo_perda_id");
                        if (subTipo == "todos" && isEmpty(conteudo.value)) {
                            showToastDanger(
                                context,
                                "Por favor preencha todos os campos customizados obrigatórios"
                            );
                            return false;
                        } else if (
                            valorSelecionado == subTipo &&
                            isEmpty(conteudo.value)
                        ) {
                            showToastDanger(
                                context,
                                "Por favor preencha todos os campos customizados obrigatórios"
                            );
                            return false;
                        } else {
                            camposCustomizados.push({
                                id: conteudo.getAttribute("data-id"),
                                valor: conteudo.value,
                            });
                        }
                    } else if (dataTipo == 4 && dataTipo == tipo) {
                        const subTipo = subTipos[0];
                        valorSelecionado = $("#select-tipo-atendimento").find("option:selected").attr("data-tipo-atendimento");
                        if (subTipo == "todos" && isEmpty(conteudo.value)) {
                            showToastDanger(
                                context,
                                "Por favor preencha todos os campos customizados obrigatórios"
                            );
                            return false;
                        } else if (
                            valorSelecionado == subTipo &&
                            isEmpty(conteudo.value)
                        ) {
                            showToastDanger(
                                context,
                                "Por favor preencha todos os campos customizados obrigatórios"
                            );
                            return false;
                        } else {
                            camposCustomizados.push({
                                id: conteudo.getAttribute("data-id"),
                                valor: conteudo.value,
                            });
                        }

                    } else {
                        camposCustomizados.push({
                            id: conteudo.getAttribute("data-id"),
                            valor: conteudo.value,
                        });
                    }
                }
            }
        }

        if (
            (
                parseInt(dataOportunidade) <= 0 ||
                parseInt(dataStatus) <= 0 ||
                isEmpty(dataStatus)
            ) || (atendimento && isEmpty(tipoAtendimento)) || (perda && isEmpty(motivo_perda_id))
        ) {
            showToastDanger(context, "Por favor preencha todos os dados");
            return false;
        } else {
            $("#overlayer-loading").addClass("show-overlayer");
            $.ajax({
                url: siteUrl("/painel/oportunidades/update-status"),
                type: "put",
                data: {
                    id: dataOportunidade,
                    status: dataStatus,
                    tipo_atendimento_id: tipoAtendimento,
                    motivo_perda_id: motivo_perda_id,
                    campos_customizados: camposCustomizados,
                },
                headers: {
                    "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr(
                        "content"
                    ),
                },
                success: function (res) {
                    if (!res.error) {
                        showToastSuccess(context);
                        window.location.reload(true);
                    } else {
                        $("#overlayer-loading").removeClass("show-overlayer");
                        showToastDanger(context, res.message);
                    }
                },
            });
        }
    });

    $(".js-reenviar-mensagem", context).on("click", function () {
        const input = $(this).closest(".mensagem__usuario");
        const mensagem_id = input.find(".messagem_id").val();

        $("#overlayer-loading").addClass("show-overlayer");
        $.ajax({
            url: siteUrl(`/painel/chat/reenviar-msg`),
            type: "post",
            headers: {
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
            },
            data: {
                mensagem_id: mensagem_id,
            },
            success: function (res) {
                $("#overlayer-loading").removeClass("show-overlayer");
                if (!res.error) {
                    $(".msg-nao-enviada").remove();
                    showToastSuccess(context, "Mensagem reenviada com sucesso");
                } else {
                    showToastDanger(context, res.message);
                }
            },
            error: function (err) {
                console.log("err", err);
                $("#overlayer-loading").removeClass("show-overlayer");
                showToastDanger(context, "Ocorreu um erro inesperado");
            },
        });
    });

    $(".js-updated-title-oportunidade", context).on(
        "blur",
        ".input-title-editable",
        function () {
            const novoNome = $(this).val();
            const antigoNome = $(".antigo-nome-oportunidade").val();
            const idOportunidade = $(".id-oportunidade").val();

            if (novoNome !== antigoNome && novoNome !== "") {
                $("#overlayer-loading").addClass("show-overlayer");
                $.ajax({
                    url: siteUrl(
                        `/painel/oportunidades/update-nome/${idOportunidade}`
                    ),
                    type: "put",
                    headers: {
                        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr(
                            "content"
                        ),
                    },
                    data: {
                        nome: novoNome,
                    },
                    success: function (res) {
                        $("#overlayer-loading").removeClass("show-overlayer");
                        $(".js-title-oportunidade").text(novoNome);
                    },
                    error: function (err) {
                        console.log("err", err);
                        $("#overlayer-loading").removeClass("show-overlayer");
                        $(".input-title-editable").val(antigoNome);
                        $(".js-title-oportunidade").text(antigoNome);
                    },
                });
            }

            if (novoNome === "") {
                location.reload();
            }
        }
    );

    $(".js-data-previsao-fechamento").on("focusin", function () {
        $(this).data("val", $(this).val());
    });

    $(".js-data-previsao-fechamento", context).on("change", function () {
        const data = $(this).val();
        const idOportunidade = $(".id-oportunidade").val();
        const prev = $(this).data("val");

        $("#overlayer-loading").addClass("show-overlayer");
        $.ajax({
            url: siteUrl(
                `/painel/oportunidades/update-previsao-fechamento/${idOportunidade}`
            ),
            type: "put",
            headers: {
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr(
                    "content"
                ),
            },
            data: {
                data: data,
            },
            success: function (res) {
                $("#overlayer-loading").removeClass("show-overlayer");
                if (!res.error) {
                    showToastSuccess(context, "Previsão de fechamento atualizada com sucesso.");
                } else {
                    $(".js-data-previsao-fechamento").val(prev);
                    showToastDanger(context, res.message);
                }
            }
        });
    });

    $(".js-switch-tarefas", context).on("click", function () {
        if ($(this).hasClass("js-show-abertas")) {
            $(this).addClass("js-show-fechadas");
            $(this).removeClass("js-show-abertas");
            $(this).text("Ver abertas");
            const total = $(".js-tarefa-concluida", context).length;
            $(".js-total-tarefas", context).text(
                `Tarefas concluídas (${total})`
            );
        } else {
            $(this).addClass("js-show-abertas");
            $(this).removeClass("js-show-fechadas");
            $(this).text("Ver concluídas");
            const total = $(".js-tarefa-aberta", context).length;
            $(".js-total-tarefas", context).text(`Tarefas abertas (${total})`);
        }
        $(".js-tarefa", context).toggleClass("d-none");
    });

    window.onscroll = function () {
        if (
            document.body.scrollTop > 60 ||
            document.documentElement.scrollTop > 60
        ) {
            $(".js-bloco-chat").addClass("onscroll");
        } else {
            $(".js-bloco-chat").removeClass("onscroll");
        }
    };

    $(".js-seleciona-produto", context).on("click", function () {
        const contextChat = $(this).closest('.oportunidade-chat');
        const produto = $(".js-autocomplete-produto", contextChat).val();
        const preco = $(".js-preco-produto", contextChat).val();

        if (produto) {
            const texto = `${produto}${preco}`;
            $(".js-input-chat", contextChat).val(texto);
            $(".js-autocomplete-produto", contextChat).val("");
        }
    });

    $(".js-cancelar-produto", context).on("click", function () {
        const contextChat = $(this).closest(".oportunidade-chat");
        $(".js-id-produto", contextChat).val("");
    });

    $(".js-imagem-chat", context).on("click", function () {
        $("#modalImagemChat img").attr("src", this.src);
        $(".fotoChatCliente").val(this.src);
        $("#modalImagemChat").modal();

        if ($(this).data("foto") === "cliente") {
            $(".btn-salvar-foto-cliente-oportunidade").show();
        } else {
            $(".btn-salvar-foto-cliente-oportunidade").hide();
        }
    });

    function limpaMoeda(valor) {

        if (valor == "" || valor == "undefined" || valor == null) {

            return 0;
        }

        if (typeof valor === 'string') {

            valor = valor.replace(/\./g, "");
            valor = valor.replace(/,/g, ".");
            valor = parseFloat(valor);
        }

        return valor;
    }

    $(".js-autocomplete-produto-oportunidades", context).each(function () {
        const input = this;
        $(input)
            .autocomplete({
                source: siteUrl("/painel/produtos/busca/autocomplete?crm=true"),
                minLength: 2,
                select: function (event, ui) {
                    $("#overlayer-loading").addClass("show-overlayer");
                    $.ajax({
                        url: siteUrl("/painel/oportunidades/adicionar-produto"),
                        type: "post",
                        headers: {
                            "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr(
                                "content"
                            ),
                        },
                        data: {
                            produto_id: ui.item.id,
                            oportunidade_id: $(input).data("oportunidade-id"),
                        },

                        success: function (res) {
                            $("#overlayer-loading").removeClass(
                                "show-overlayer"
                            );

                            if (res.error) {
                                showToastDanger(context, res.message);
                            } else {
                                showToastSuccess(context);
                                const tr = res.html;
                                const item_id = res.itemId;
                                $(".js-tabela-produtos tbody", context).append(
                                    tr
                                );
                                Eloca.load($(`#tr-item-${item_id}`));
                                autalizarTotalTabela(`#tr-item-${item_id}`);
                                if (
                                    $(".js-racerregar-modelo").hasClass(
                                        "d-none"
                                    )
                                ) {
                                    $(".js-racerregar-modelo").removeClass(
                                        "d-none"
                                    );
                                }
                            }
                        },
                        error: function (err) {
                            $("#overlayer-loading").removeClass(
                                "show-overlayer"
                            );
                            showToastDanger(context);
                        },
                    });
                },
            })
            .autocomplete("instance")._renderItem = function (ul, item) {
                const url = siteUrl(`/painel/pedidos/produtosEdit/add/${item.id}`);
                let sku = "";
                if (item.sku && item.sku != null) {
                    sku = ` - SKU: ${item.sku}`;
                }

                return $("<li class='lista-produto'>")
                    .append(`#${item.id} - ${item.nome}${sku}`)
                    .appendTo(ul);
            };
    });

    $(".js-autocomplete-produto-oportunidades-ccl", context).each(function () {
        const input = this;
        $(input)
            .autocomplete({
                source: siteUrl("/painel/produtos/busca/autocomplete?crm=true"),
                minLength: 2,
                select: function (event, ui) {
                    $("#overlayer-loading").addClass("show-overlayer");
                    $.ajax({
                        url: siteUrl("/painel/oportunidades/ccl/produto"),
                        type: "post",
                        headers: {
                            "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr(
                                "content"
                            ),
                        },
                        data: {
                            produto_id: ui.item.id,
                            oportunidade_id: $(input).data("oportunidade-id"),
                        },

                        success: function (res) {
                            $("#overlayer-loading").removeClass(
                                "show-overlayer"
                            );

                            if (res.error) {
                                showToastDanger(context, res.message);
                            } else {
                                showToastSuccess(context);
                                const tr = res.html;
                                const item_id = res.itemId;
                                $(".js-tabela-produtos tbody", context).append(
                                    tr
                                );
                                Eloca.load($(`#tr-item-${item_id}`));
                                atualizarDadosOportunidade(); //atualizarTotalTabelaCCL();
                                if ($('.js-racerregar-modelo').hasClass('d-none')) {
                                    $('.js-racerregar-modelo').removeClass('d-none');
                                }
                            }
                        },
                        error: function (err) {
                            $("#overlayer-loading").removeClass(
                                "show-overlayer"
                            );
                            showToastDanger(context);
                        },
                    });
                },
            })
            .autocomplete("instance")._renderItem = function (ul, item) {
                const url = siteUrl(`/painel/pedidos/produtosEdit/add/${item.id}`);
                let sku = "";
                if (item.sku && item.sku != null) {
                    sku = ` - SKU: ${item.sku}`;
                }

                return $("<li class='lista-produto'>")
                    .append(`#${item.id} - ${item.nome}${sku}`)
                    .appendTo(ul);
            };
    });

    $(
        ".js-select-faixa-produto, .js-input-quantidade, .js-input-preco",
        context
    ).on("change", function () {
        const tr = $(this).closest(".js-tr-produtos");
        autalizarTotal(tr);
    });

    $(".js-chat-mensagem", context).on("mouseover", function () {
        $(".js-dropdown-menu-chat").addClass('d-none');
        $(".js-dropdown-menu-chat", this).removeClass('d-none');
    });
    if ($(context).hasClass("js-chat-mensagem")) {
        $(context).on("mouseover", function () {
            $(".js-dropdown-menu-chat").addClass('d-none');
            $(".js-dropdown-menu-chat", this).removeClass('d-none');
        });
    }

    $(".js-btn-encaminhar-msg", context).on("click", function () {
        let modal = $(".js-modal-encaminhar-msg");
        $("#js-chat-id", modal).val($(this).data('chat-id'));
        $("#encaminharMensagem").modal();
    });

    $(".js-btn-responder-msg", context).on("click", function () {
        const chatId = $(this).data('chat-id');
        const msg = $(`#js-mensagem-${chatId}`).clone();
        $(".dropdown", msg).remove();
        $(".msg-nao-enviada", msg).remove();
        $(".js-status-message", msg).remove();
        $(".arrow", msg).remove();
        $(".mensagem__resposta", msg).remove();
        $(".js-aguardando-resposta", msg).remove();

        const msgHtml = msg.get(0).outerHTML;
        const contextChat = $(this).closest(".oportunidade-chat");

        $(".js-bloco-mensagem-resposta .js-conteudo-resposta", contextChat).html(`<div class="mensagens">${msgHtml}</div>`)
        $(".js-bloco-mensagem-resposta", contextChat).removeClass("d-none");

        $(".js-msg-resposta-id", contextChat).val(chatId);
        $(".js-input-chat", contextChat).focus();
    });

    $(".js-btn-cancelar-resposta", context).on("click", function () {
        const contextChat = $(this).closest(".oportunidade-chat");

        $(this).closest(".js-bloco-mensagem-resposta").addClass("d-none");
        $(".js-msg-resposta-id", contextChat).val("");
    })


    $(context).on("change",
        ".js-select-faixa-produto-ccl, .js-input-preco-ccl, .js-input-quantidade-ccl",
        function () {
            const tr = $(this).closest(".js-tr-produtos");
            atualizarTotalCCL(tr);
        });

    function autalizarTotal(tr) {
        const item_id = $(".js-item-id", tr).val();
        const faixa_id = $(".js-select-faixa-produto", tr).val();
        const preco = $(".js-input-preco", tr).val();
        const quantidade = $(".js-input-quantidade", tr).val();

        $.ajax({
            url: siteUrl("/painel/oportunidades/atualizar-produto"),
            type: "put",
            headers: {
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
            },
            data: { item_id, faixa_id, preco, quantidade },
            success: function (res) {
                $("#overlayer-loading").removeClass("show-overlayer");
                if (res.error) {
                    showToastDanger(context, res.message);
                } else {
                    showToastSuccess(context);
                    if (quantidade > 0) {
                        const novaTr = res.html;
                        $(tr, context).replaceWith(novaTr);
                        Eloca.load($(`#tr-item-${item_id}`));
                    } else {
                        $(tr).remove();
                    }
                    autalizarTotalTabela(`#tr-item-${item_id}`);
                }
            },
            error: function (err) {
                $("#overlayer-loading").removeClass("show-overlayer");
                showToastDanger(context);
            },
        });
    }

    function atualizarTotalCCL(tr) {
        const item_id = $(".js-item-id", tr).val();
        const faixa_id = $(".js-select-faixa-produto-ccl", tr).val();
        const preco = $(".js-input-preco-ccl", tr).val();
        const quantidade = $(".js-input-quantidade-ccl", tr).val();

        $.ajax({
            url: siteUrl("/painel/oportunidades/ccl/produto"),
            type: "put",
            headers: {
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
            },
            data: { item_id, faixa_id, preco, quantidade },
            success: function (res) {
                $("#overlayer-loading").removeClass("show-overlayer");
                if (res.error) {
                    showToastDanger(context, res.message);
                } else {
                    showToastSuccess(context);
                    if (quantidade > 0) {
                        const novaTr = res.html;
                        $(tr, context).replaceWith(novaTr);
                        //Eloca.load($(`#tr-item-${item_id}`));
                        $(".money").mask("000.000.000.000.000,00", { reverse: true });
                    } else {
                        $(tr).remove();
                    }
                    atualizarDadosOportunidade();
                }
            },
            error: function (err) {
                $("#overlayer-loading").removeClass("show-overlayer");
                showToastDanger(context);
            },
        });
    }

    function autalizarTotalTabela(id) {
        let total = 0;
        if (id == "tabela_produtos") {
            id = "#tabela_produtos";
            $(id)
                .find(".subtotalSpanProd")
                .each(function () {
                    let val = $(this).data("id");
                    val = limpaMoeda(val);
                    total += val;
                });
        } else {
            $(id).closest("table").find("tbody")
                .find(".subtotalSpanProd")
                .each(function () {
                    let val = $(this).data("id");
                    val = limpaMoeda(val);
                    total += val;
                });
        }

        total += parseFloat($(id)
                    .closest("table")
                    .find("tfoot")
                    .find(".js-valor-protecao-ccl")
                    .attr("data-valor") ?? 0);


        total += limpaMoeda(
            $(id)
                .closest("table")
                .find("tfoot")
                .find("#deslocamento_entrega")
                .val());

        total += limpaMoeda(
            $(id)
                .closest("table")
                .find("tfoot")
                .find("#deslocamento_retirada")
                .val());
        $(id)
            .closest("table")
            .find("#produtoOportunidadeTotal")
            .html(
                total.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                })
            );

    }


    function atualizarTotalTabelaCCL() {
        let total = 0;
        let valorProtecao = 0;
        let totalDesconto = 0;
        let totalDescontoPercentual = 0;
        $(".subtotalSpanProd").each(function () {

            let val = $(this).data("id");
            val = limpaMoeda(val);
            total += val;

           const protecao = $(this).data("percentual-protecao") ?? 0;
           const desconto = $(this).data("desconto-valor") ?? 0;
           valorProtecao += (parseFloat(protecao) / 100) * val;
           totalDesconto += parseFloat(desconto);
        });


        let subtotalSemDesconto = total + totalDesconto;
        totalDescontoPercentual = (totalDesconto / subtotalSemDesconto) * 100;

        if ($("input[name='protecao']").length > 0 && !$("input[name='protecao']").prop('checked')) {
            valorProtecao = 0;
        }

        $("#produtoOportunidadeTotal").html(
            total.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
            })
        );

        totalDescontoPercentual = !isNaN(totalDescontoPercentual) ? totalDescontoPercentual : 0;
        totalDesconto = !isNaN(totalDesconto) ? totalDesconto : 0;
        totalDescontoPercentual = totalDescontoPercentual.toLocaleString("pt-br", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });

        $("#produtoOportunidadeDescontoTotal").html(
            totalDesconto.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
            }) + '<br/> ' + totalDescontoPercentual + ' %'
        );

        total += valorProtecao;
        let valorProtecaoProtecaoBRL = valorProtecao.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
        });

        $(".js-valor-protecao-ccl").html(valorProtecaoProtecaoBRL);
        $(".js-valor-protecao-ccl").data("valor", valorProtecao);

        let seletorValorEntrega = $("input[name='valor_deslocamento_entrega']");
        let seletorValorRetirada = $("input[name='valor_deslocamento_retirada']");

        if ($("input[name='entrega']").length > 0 && $("input[name='entrega']").prop('checked')) {

            $(".js-tr-deslocamento-entrega").show();
            total += (seletorValorEntrega.length > 0) ? limpaMoeda(seletorValorEntrega.val()) : 0;
        } else {

            $(".js-tr-deslocamento-entrega").hide();
            $("input[name='valor_deslocamento_entrega']").val("0");
        }

        total += (seletorValorRetirada.length > 0) ? limpaMoeda(seletorValorRetirada.val()) : 0;
        $(".js-total-produtos-oportunidade-ccl").html(
            total.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
            })
        );
    }
    function atualizarTotalTabelaCCLChat(sidebar) {
        const table = $(sidebar).find(".js-tabela-produtos-chat");
        let total = 0;
        let valorProtecao = 0;
        let totalDesconto = 0;
        $(table).find(".subtotalSpanProd").each(function () {
            let val = $(this).data("id");
            val = limpaMoeda(val);
            total += val;
            const protecao = $(this).data("percentual-protecao") ?? 0;
            const desconto = $(this).data("desconto-valor") ?? 0;
            valorProtecao += (parseFloat(protecao) / 100) * val;
            totalDesconto += parseFloat(desconto);
        });

        if (
            $(sidebar).find("input[name='protecao']").length > 0 &&
            !$(sidebar).find("input[name='protecao']").prop("checked")
        ) {
            valorProtecao = 0;
        }

        $(sidebar)
            .find("#produtoOportunidadeTotal")
            .html(
                total.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                })
            );

        totalDesconto = !isNaN(totalDesconto) ? totalDesconto : 0;
        total += valorProtecao;
        let valorProtecaoProtecaoBRL = valorProtecao.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
        });

        $(table).find(".js-valor-protecao-ccl").html(valorProtecaoProtecaoBRL);
        $(table).find(".js-valor-protecao-ccl").attr("data-valor", valorProtecao);

        let seletorValorEntrega = $(sidebar).find(
            "input[name='valor_deslocamento_entrega']"
        );
        let seletorValorRetirada = $(sidebar).find(
            "input[name='valor_deslocamento_retirada']"
        );

        if (
            $(sidebar).find("input[name='entrega']").length > 0 &&
            $(sidebar).find("input[name='entrega']").prop("checked")
        ) {
            $(table).find(".js-tr-deslocamento-entrega").show();
            total +=
                seletorValorEntrega.length > 0
                    ? limpaMoeda(seletorValorEntrega.val())
                    : 0;
        } else {
            $(table).find(".js-tr-deslocamento-entrega").hide();
            $(table).find("input[name='valor_deslocamento_entrega']").val("0");
        }

        total +=
            seletorValorRetirada.length > 0
                ? limpaMoeda(seletorValorRetirada.val())
                : 0;
        $(table)
            .find("#produtoOportunidadeTotal")
            .html(
                total.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                })
            );
    }

    $(".js-dados-oportunidade", context).on("change", function () {
        let oportunidadeId = $("input[name='oportunidade_id']").val();
        let observacao = $("textarea[name='observacao']").val();
        let valorDeslocamentoEntrega = $("input[name='valor_deslocamento_entrega']").val();
        valorDeslocamentoEntrega = limpaMoeda(valorDeslocamentoEntrega);

        let valorDeslocamentoRetirada = $("input[name='valor_deslocamento_retirada']").val();
        valorDeslocamentoRetirada = limpaMoeda(valorDeslocamentoRetirada);

        updateDaodsOportunidade(
            oportunidadeId,
            observacao,
            valorDeslocamentoEntrega,
            valorDeslocamentoRetirada,
            "tabela_produtos"
        );
    });
    $(".js-tabela-produto-chat", context).on(
        "change",
        ".js-dados-oportunidade",
        function () {
            const tabela = $(this).closest(".js-tabela-produto-chat");
            const tabelaPrincipal = $("#tabela_produtos");
            const trId = "#" + $(this).closest('tr').attr('id');
            let oportunidadeId = $("input[name='oportunidade_id']").val();

            let observacao = $(tabela).find("textarea[name='observacao']").val();
            $(tabelaPrincipal)
                .find("textarea[name='observacao']")
                .val(observacao);
            let valorDeslocamentoEntrega = $(tabela)
                .find("input[name='valor_deslocamento_entrega']")
                .val();
            $(tabelaPrincipal)
                .find("input[name='valor_deslocamento_entrega']")
                .val(valorDeslocamentoEntrega);

            valorDeslocamentoEntrega = limpaMoeda(valorDeslocamentoEntrega);

            let valorDeslocamentoRetirada = $(tabela)
                .find("input[name='valor_deslocamento_retirada']")
                .val();
            $(tabelaPrincipal)
                .find("input[name='valor_deslocamento_retirada']")
                .val(valorDeslocamentoRetirada);

            valorDeslocamentoRetirada = limpaMoeda(valorDeslocamentoRetirada);


            updateDaodsOportunidade(
                oportunidadeId,
                observacao,
                valorDeslocamentoEntrega,
                valorDeslocamentoRetirada,
                trId
            );
        }
    );

    function updateDaodsOportunidade(
        oportunidadeId,
        observacao,
        valorDeslocamentoEntrega,
        valorDeslocamentoRetirada,
        idAtaulizacao
    ) {
        $.ajax({
            url: siteUrl(`/painel/oportunidades/dados`),
            type: "put",
            headers: {
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
            },
            data: {
                oportunidade_id: oportunidadeId,
                observacao: observacao,
                valor_deslocamento_entrega: valorDeslocamentoEntrega,
                valor_deslocamento_retirada: valorDeslocamentoRetirada,
            },
            success: function (res) {
                const result = JSON.parse(res);
                if (result.status == "erro") {
                    showToastDanger($("body"), result.mensagem);
                }
                if (result.status == "sucesso") {
                    autalizarTotalTabela("tabela_produtos");
                    if (idAtaulizacao != "tabela_produtos") {
                        autalizarTotalTabela(idAtaulizacao);
                    }
                    showToastSuccess(context, result.mensagem);
                }
            },
            error: function (err) {
                showToastDanger(
                    context,
                    "Ocorreu um erro ao tentar incluir os produtos"
                );
            },
        });
    }

    function atualizarDadosOportunidade(reload = false) {
        let oportunidadeId = $("input[name='oportunidade_id']").val();
        let protecao = ($("input[name='protecao']").length > 0) ? $("input[name='protecao']").prop('checked') : null;
        let entrega = ($("input[name='entrega']").length > 0) ? $("input[name='entrega']").prop("checked") : null;
        let dataEntrega = $("input[name='data_entrega']").val();
        let observacao = $("textarea[name='observacao']").val();
        let clienteEnderecoId = $("select[name='cliente_endereco_id']").val();

        let valorDeslocamentoEntrega = $("input[name='valor_deslocamento_entrega']").val();
        valorDeslocamentoEntrega = limpaMoeda(valorDeslocamentoEntrega);

        let valorDeslocamentoRetirada = $("input[name='valor_deslocamento_retirada']").val();
        valorDeslocamentoRetirada = limpaMoeda(valorDeslocamentoRetirada);

        if (!isEmpty(dataEntrega)) {

            dataEntrega = dataEntrega.split('/');
            dataEntrega = dataEntrega.reverse().join('-');
        }

        if (!entrega) {

            valorDeslocamentoEntrega = 0;
        }

        $.ajax({
            url: siteUrl(`/painel/oportunidades/ccl/dados`),
            type: "put",
            headers: {
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
            },
            data: {
                oportunidade_id: oportunidadeId,
                protecao: protecao,
                entrega: entrega,
                data_entrega: dataEntrega,
                valor_deslocamento_entrega: valorDeslocamentoEntrega,
                valor_deslocamento_retirada: valorDeslocamentoRetirada,
                observacao: observacao,
                cliente_endereco_id: clienteEnderecoId,
            },
            success: function (res) {
                if (res.status == 'erro') {
                    showToastDanger($('body'), res.mensagem);
                }

                atualizarTotalTabelaCCL();
                if (reload) {

                    window.location.reload();
                }
            },
            error: function (err) {
                console.log("err", err);
            },
        });
    }

    function atualizarDadosOportunidadeChat(sidebar) {
        let oportunidadeId = $("input[name='oportunidade_id']").val();
        let protecao =
            $(sidebar).find("input[name='protecao']").length > 0
                ? $(sidebar).find("input[name='protecao']").prop("checked")
                : null;
        let entrega =
            $(sidebar).find("input[name='entrega']").length > 0
                ? $(sidebar).find("input[name='entrega']").prop("checked")
                : null;
        let dataEntrega = $("input[name='data_entrega']").val();
        let observacao = $(sidebar).find("textarea[name='observacao']").val();
        let clienteEnderecoId = $(sidebar)
            .find("select[name='cliente_endereco_id']")
            .val();

        let valorDeslocamentoEntrega = $(sidebar)
            .find("input[name='valor_deslocamento_entrega']")
            .val();
        valorDeslocamentoEntrega = limpaMoeda(valorDeslocamentoEntrega);

        let valorDeslocamentoRetirada = $(sidebar)
            .find("input[name='valor_deslocamento_retirada']")
            .val();
        valorDeslocamentoRetirada = limpaMoeda(valorDeslocamentoRetirada);

        if (!isEmpty(dataEntrega)) {
            dataEntrega = dataEntrega.split("/");
            dataEntrega = dataEntrega.reverse().join("-");
        }

        if (!entrega) {
            valorDeslocamentoEntrega = 0;
        }

        $.ajax({
            url: siteUrl(`/painel/oportunidades/ccl/dados`),
            type: "put",
            headers: {
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
            },
            data: {
                oportunidade_id: oportunidadeId,
                protecao: protecao,
                entrega: entrega,
                data_entrega: dataEntrega,
                valor_deslocamento_entrega: valorDeslocamentoEntrega,
                valor_deslocamento_retirada: valorDeslocamentoRetirada,
                observacao: observacao,
                cliente_endereco_id: clienteEnderecoId,
            },
            success: function (res) {
                if (res.status == "erro") {
                    showToastDanger($("body"), res.mensagem);
                }

                atualizarTotalTabelaCCL();
                atualizarTotalTabelaCCLChat(sidebar);
            },

            error: function (err) {
                console.log("err", err);
            },
        });
    }

    $( document ).ready(function() {
        if (parseInt(window.integracaoCcl) > 0) {

            if (
                $(`.js-tabela-produtos`,
                    document
                ).length &&
                $(`.js-dados-integracao-ccl-oportunidade`,
                    document
                ).length > 0
            ) {
                atualizarDadosOportunidade();
            }
        }
    });

    $(".js-dados-integracao-ccl-oportunidade", context).on("change", function () {

        atualizarDadosOportunidade();
    });
    $(".js-dados-integracao-ccl-oportunidade-chat", context).on("change", function () {
        const sidebar = $(this).closest(".sidebar-chat");
        atualizarDadosOportunidadeChat(sidebar);
    });

    $(".js-enviar-ccl", context).on("click", function () {
        $("#overlayer-loading").addClass("show-overlayer");
        let oportunidadeId = $("input[name='oportunidade_id']").val();
        $("#modalConfirmaEnvioCotacaoCCL").modal('hide');
        $.ajax({
            url: siteUrl(`/painel/oportunidades/ccl/dados`),
            type: "post",
            headers: {
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
            },
            data: {
                oportunidade_id: oportunidadeId,
            },
            success: function (res) {
                $("#overlayer-loading").removeClass("show-overlayer");
                if (res.status) {
                    if (item.status == "erro") {
                        showToastDanger(context, item.mensagem);
                    } else {
                        showToastSuccess(context, item.mensagem);
                    }

                    setTimeout(function () {
                        window.location.reload();
                    }, 3000);
                }
                else {
                    const len = res.length;
                    for (let i = 0; i < res.length; i++) {
                        item = res[i];
                        setTimeout(function () {
                            if (item.status == "erro") {
                                showToastDanger(context, item.mensagem);
                            } else {
                                showToastSuccess(context, item.mensagem);
                            }
                            if (i === len - 1) {
                                setTimeout(function () {
                                    window.location.reload();
                                }, 3000);
                            }
                        }, 2000 * i);
                    }
                }
            },
            error: function (err) {
                $("#overlayer-loading").removeClass("show-overlayer");
                showToastDanger(
                    context,
                    err.responseJSON?.mensagem ??
                    "Não foi possivel atualizar a oportunidade."
                );
            },
        });
    });

    $(".js-atendente-oportunidade", context).on("change", function () {
        $("#overlayer-loading").addClass("show-overlayer");
        $(".js-pipeline-oportunidade option", context).remove();
        let id = this.value;
        if (!this.value) {
            id = 0
        }
        $.ajax({
            url: siteUrl(
                `/painel/oportunidades/pipelines-atendente/${id}`
            ),
            type: "get",
            success: function (res) {
                $(res).each(function (i, item) {
                    const selected = item.padrao ? 'SELECTED' : '';
                    $(".js-pipeline-oportunidade", context).append(
                        `<option value="${item.id}" ${selected}>${item.value}</option>`
                    );
                });
                $("#overlayer-loading").removeClass("show-overlayer");
            },
            error: function (err) {
                $("#overlayer-loading").removeClass("show-overlayer");
                showToastDanger(context);
            },
        });
    });

    function getLocacao(clienteId) {
        if (clienteId == undefined) {
            $(".js-input-locacao option", context).remove();
            $(".js-bloco-locacao", context).addClass("d-none");
            return;
        }
        $.ajax({
            url: siteUrl("/painel/clientes/busca/locacoes/" + clienteId),
            type: "get",
            success: function (res) {
                $(".js-input-locacao option", context).remove();
                if (res.length > 1) {
                    $(".js-bloco-locacao", context).removeClass("d-none");
                    res.forEach(function (item) {
                        $(".js-input-locacao", context).append(
                            `<option value="${item.id}">${item.value}</option>`
                        );
                    });
                } else {
                    $(".js-bloco-locacao", context).addClass("d-none");
                }
            },
        });
    }

    function setNovoCliente(nome) {
        $(".js-valida-oportunidade", context).data("novoCliente", true);
        $(".js-bloco-autocomplete", context).addClass("d-none");
        $(".js-bloco-novo-cliente", context).removeClass("d-none");
        $(".js-bloco-novo-cliente #cliente_nome", context).val(nome);
    }

    function cancelNovoCliente() {
        $(".js-valida-oportunidade", context).data("novoCliente", false);
        $(".js-bloco-autocomplete", context).removeClass("d-none");
        $(".js-bloco-novo-cliente", context).addClass("d-none");
        $(".js-bloco-novo-cliente #cliente_nome", context).val("");
    }

    function somaOportunidades() {
        const alturaMinimaBloco = 34;
        const totalOportunidades = $(".js-fase .lista-oportunidades li").length;
        const totalFases = $(".js-fase", context).length;
        const alturaTotal = totalFases * 50 - totalFases * alturaMinimaBloco;
        $(".js-fase", context).each(function () {
            const fase = this;
            const faseId = $(this).data("id");
            let quantidade = 0;
            let total = 0.0;
            let alturaBloco = alturaMinimaBloco;
            let totalConversasNaoLidas = 0;
            $(".lista-oportunidades li", fase).each(function () {
                quantidade++;
                total += parseFloat($(this).data("valor"));
                if (
                    $(this).hasClass(
                        "js-mensagem-nao-lida"
                    )
                ) {
                    totalConversasNaoLidas++;
                }
            });
            $(".js-quantidade-oportunidades-fase", fase).text(
                `${quantidade} oportunidades`
            );
            if (totalConversasNaoLidas > 0) {
                $(".js-total-mensagens-fase", fase).html(
                    `<span class="mensagem-nao-lida">${totalConversasNaoLidas}</span>`
                );
            }

            $(".js-total-oportunidades-fase", fase).text(
                numeral(total).format("$ 0,0.00")
            );

            if (quantidade > 0) {
                alturaBloco += (quantidade * alturaTotal) / totalOportunidades;
            }

            $(`#funil-fase-${faseId} .js-quant-fases-funil`).html(quantidade);
            $(`#funil-fase-${faseId} .js-valor-fases-funil`).html(
                numeral(total).format("$ 0,0.00")
            );
            $(`#funil-fase-${faseId} .funil`).css(
                "height",
                `${alturaBloco.toFixed(2)}px`
            );
        });
    }

    function ordenaOportunidades() {
        $(".js-fase", context).each(function () {
            const fase = this;
            const itens = $(".lista-oportunidades li", fase);
            let itensOrdem = [];
            $(itens).each(function (index, li) {
                if ($(li).data("hora")) {
                    itensOrdem[index] = {
                        dataHora: $(li).data("hora"),
                        li: li,
                    };
                }
            });
            itensOrdem.sort((a, b) => {
                if (a.dataHora > b.dataHora) {
                    return 1;
                }
                if (a.dataHora < b.dataHora) {
                    return -1;
                }
                return 0;
            });
            itensOrdem.forEach(function (item) {
                $(".lista-oportunidades", fase).prepend(item.li);
            });
        });
    }

    $(".js-seleciona-msg-final-protocolo", context).on("click", function () {
        if ($(this).is(":checked")) {
            $(".js-msg-final-protocolo").removeClass("d-none");
            $(".js-input-msg-final-protocolo").attr("required", "required");
        } else {
            $(".js-msg-final-protocolo").addClass("d-none");
            $(".js-input-msg-final-protocolo").removeAttr("required");
        }
    });

    $(".js-seleciona-pipe-line-oportunidade", context).on("click", function () {
        if ($(this).is(":checked")) {
            $(".js-pipe-line-oportunidade").removeClass("d-none");
            $(".js-input-pipe-line-oportunidade-fase").attr(
                "required",
                "required"
            );
        } else {
            $(".js-pipe-line-oportunidade").addClass("d-none");
            $(".js-input-pipe-line-oportunidade-fase").removeAttr("required");
        }
    });

    $(".js-informar-tempo-sem-atendimento", context).on("click", function () {
        if ($(this).is(":checked")) {
            $(".js-tempo-sem-atendimento").removeClass("d-none");
            $(".js-input-tempo-sem-atendimento").attr("required", "required");
        } else {
            $(".js-tempo-sem-atendimento").addClass("d-none");
            $(".js-input-tempo-sem-atendimento").removeAttr("required");
        }
    });

    $(".js-seleciona-carrinho-pipe-line-oportunidade", context).on(
        "click",
        function () {
            if ($(this).is(":checked")) {
                $(".js-carrinho-pipe-line-oportunidade").removeClass("d-none");
                $(".js-carrinho-input-pipe-line-oportunidade-fase").attr(
                    "required",
                    "required"
                );
            } else {
                $(".js-carrinho-pipe-line-oportunidade").addClass("d-none");
                $(".js-carrinho-input-pipe-line-oportunidade-fase").removeAttr(
                    "required"
                );
            }
        }
    );

    $(".js-seleciona-contato-pipe-line-oportunidade", context).on(
        "click",
        function () {
            if ($(this).is(":checked")) {
                $(".js-contato-pipe-line-oportunidade").removeClass("d-none");
                $(".js-contato-input-pipe-line-oportunidade-fase").attr(
                    "required",
                    "required"
                );
            } else {
                $(".js-contato-pipe-line-oportunidade").addClass("d-none");
                $(".js-contato-input-pipe-line-oportunidade-fase").removeAttr(
                    "required"
                );
            }
        }
    );

    $(".js-input-pipe-line-oportunidade", context).on("change", function () {
        let id = $(this).find(":selected").val();
        buscarFasePipelines(id, ".js-input-pipe-line-oportunidade-fase");
    });

    $(".js-carrinho-input-pipe-line-oportunidade", context).on(
        "change", function () {
            let id = $(this).find(":selected").val();
            buscarFasePipelines(
                id,
                ".js-carrinho-input-pipe-line-oportunidade-fase"
            );
        }
    );

    $(".js-contato-input-pipe-line-oportunidade", context).on(
        "change",
        function () {
            let id = $(this).find(":selected").val();
            buscarFasePipelines(
                id,
                ".js-contato-input-pipe-line-oportunidade-fase"
            );

        }
    );

    $(".js-input-oportunidade-api", context).on(
        "change",
        function () {
            let id = $(this).find(":selected").val();
            buscarFasePipelines(
                id,
                ".js-input-oportunidade-fase-api"
            );

        }
    );

    function buscarFasePipelines(id, classeSelect) {
        $.ajax({
            url: siteUrl(`/painel/oportunidades-pipelines/listar-fases/${id}`),
            type: "get",
            success: function (res) {
                if (res.error) {
                    showToastDanger(context, res.message);
                } else {
                    $(classeSelect).html("");
                    res.fases.forEach((fase) => {
                        $(classeSelect).append(
                            `<option value="${fase.id}">${fase.value}</option>`
                        );
                    });
                }
            },
            error: function (res) {
                showToastDanger(context, res.message);
            },
        });
    }

    $(".js-salvar-contato", context).on("click", function () {
        const id = $(this).attr("data-id");
        if ($(`#salvarClientLoading${id}`).hasClass("d-none")) {
            $(`#salvarClientLoading${id}`).removeClass("d-none");
        }
        if (!$(`#btnSalvarCliente${id}`).hasClass("d-none")) {
            $(`#btnSalvarCliente${id}`).addClass("d-none");
        }

        let dados = $(`#cliente_contato${id}`, context).html();
        dados = dados.trim().split("<br>");
        const nome = dados[0].replace("<br>", "").replace("\n", "") ?? "";
        const telefone = dados[1]
            .replace("<br>", "")
            .replace("\n", "")
            .replace("+", "")
            .trim();
        $.ajax({
            url: siteUrl(`/painel/clientes/buscar/${telefone}/${nome}`),
            type: "get",
            statusCode: {
                204: function () {
                    $.ajax({
                        url: siteUrl(`/painel/clientes/criar-via-chat`),
                        type: "post",
                        data: {
                            nome: nome,
                            telefone1: telefone,
                        },
                        headers: {
                            "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
                        },
                        success: function (res) {
                            if (
                                !$(`#salvarClientLoading${id}`).hasClass(
                                    "d-none"
                                )
                            ) {
                                $(`#salvarClientLoading${id}`).addClass(
                                    "d-none"
                                );
                            }
                            if ($(`#btnSalvarCliente${id}`).hasClass("d-none")) {
                                $(`#btnSalvarCliente${id}`).removeClass(
                                    "d-none"
                                );
                            }
                            const contextChat = $(this).closest(".oportunidade-chat");
                            showToastSuccess(context, res.message);
                            $(".js-criar-oportunidade-chat", contextChat).click();
                            $("#cliente", contextChat).val(res.cliente.nome);
                            $("#fase_id", contextChat).val($("#fase_id option:first", contextChat).val()).change();
                            $('input[name="cliente_id"]', contextChat).val(
                                res.cliente.id
                            );
                        },
                        error: function (err) {
                            if (
                                !$(`#salvarClientLoading${id}`).hasClass(
                                    "d-none"
                                )
                            ) {
                                $(`#salvarClientLoading${id}`).addClass(
                                    "d-none"
                                );
                            }
                            if ($(`#btnSalvarCliente${id}`).hasClass("d-none")) {
                                $(`#btnSalvarCliente${id}`).removeClass(
                                    "d-none"
                                );
                            }
                            showToastDanger(context, err.message);
                        },
                    });
                },
                200: function () {
                    if (!$(`#salvarClientLoading${id}`).hasClass("d-none")) {
                        $(`#salvarClientLoading${id}`).addClass("d-none");
                    }
                    if ($(`#btnSalvarCliente${id}`).hasClass("d-none")) {
                        $(`#btnSalvarCliente${id}`).removeClass("d-none");
                    }
                    $(".js-criar-oportunidade-chat").click();
                },
            },
            success: function (res) {
                if (res && (typeof res.cliente != 'undefined')) {
                    setTimeout(() => {
                        $("#fase_id")
                            .val($("#fase_id option:first").val())
                            .change();
                        $("#cliente").val(res.cliente.nome);
                        $('input[name="cliente_id"]').val(res.cliente.id);
                        const nomeOportunidade = res.cliente.nome.split(' ')[0] + ' - ' + res.cliente.telefone1;
                        $('input[name="nome"]').val(nomeOportunidade);
                    }, 700);
                }
            },
            error: function (err) {
                $("#novoClienteModalfechar").click();
                showToastDanger(context, err.message);
            },
        });
    });

    $(".js-autocomplete-produto-relatorio-oportunidades", context).each(function () {
        const input = this;
        $(input)
            .autocomplete({
                source: siteUrl("/painel/produtos/busca/autocomplete"),
                minLength: 2,
                select: function (event, ui) {
                    setTimeout(function () {
                        $(input)
                            .closest(".form-group")
                            .find(".js-input-autocomplete")
                            .val(ui.item.id);
                        $(input).val(ui.item.nome);
                        $(".js-form-filtro-oportunidades", context).submit();
                    }, 1);
                    Eloca.load($(`#tr-item-${item_id}`));
                },
            })
            .autocomplete("instance")._renderItem = function (ul, item) {
                let sku = "";
                if (item.sku && item.sku != null) {
                    sku = ` - SKU: ${item.sku}`;
                }

                return $("<li class='lista-produto'>")
                    .append(`#${item.id} - ${item.nome}${sku}`)
                    .appendTo(ul);
            };
    });

    $("#produto-autocomplete", context).on('change', function () {
        if ($(this).val() == '') {
            $(this)
                .closest(".form-group")
                .find(".js-input-autocomplete")
                .val('');
            $(".js-form-filtro-oportunidades", context).submit();
        }
    });

    $(".js-janela-midia", context).on("click", function () {
        const janelaId = $(this).data("id");
        const div = $(this).closest(".oportunidade-chat");
        const chat = $(".js-bloco-chat", div);
        const janela = $(janelaId).find(".js-body-modal-ajax");
        $(janela).html(`
            <div class="mt-5 mb-4 pb-1">
                <div class="loading-circle"><div></div><div></div><div></div><div></div></div>
            </div>
        `);
        $(div).toggleClass("row");
        $(chat).toggleClass("col-9");
        $(janelaId).toggleClass("d-none");

        if (!$(janelaId).hasClass("d-none")) {
            const url = $(this).data("url");
            $.ajax({
                url: url,
                type: "get",
                success: function (res) {
                    $(janela).html(res);
                    Eloca.load($(janela));
                },
            });
        }
    });

    $(".js-racerregar-modelo", context).on("click", function () {
        const id = "#modalImprimirOportunidadeConteudo";
        $(id).html(`
            <div class="mt-5 mb-4 pb-1">
                <div class="loading-circle"><div></div><div></div><div></div><div></div></div>
            </div>
        `);
        const url = $(this).data("url");
        $.ajax({
            url: url,
            type: "get",
            success: function (res) {
                $(id).html(res);
            },
        });
    });


    function getTiposCCL() {

        $("#overlayer-loading").addClass("show-overlayer");
        $.ajax({
            url: siteUrl("/painel/oportunidades/ccl/calculadora/tipos"),
            type: "get",
            data: {
                oportunidade_id: $("input[name='oportunidade_id']").val(),
            },
            success: function (res) {
                $("#overlayer-loading").removeClass("show-overlayer");
                if (!res.erro) {
                    $("select[name='tipo_ccl']").html("");
                    let options = res.dados;
                    $(options).each(function (index, option) {
                        $("select[name='tipo_ccl']").append(`
                            <option value="${option.id}">${option.nome}</option>
                        `);
                    });
                }
            },
        });
    }

    function getBasesCCL() {

        $("#overlayer-loading").addClass("show-overlayer");
        $.ajax({
            url: siteUrl("/painel/oportunidades/ccl/calculadora/bases"),
            type: "get",
            data: {
                oportunidade_id: $("input[name='oportunidade_id']").val(),
            },
            success: function (res) {
                $("#overlayer-loading").removeClass("show-overlayer");
                if (!res.erro) {
                    $("select[name='base_ccl']").html("");
                    let options = res.dados;
                    $(options).each(function (index, option) {
                        $("select[name='base_ccl']").append(`
                            <option value="${option.id}">${option.nome}</option>
                        `);
                    });
                }
            },
        });
    }

    function isIntString(str) {

        return /^\d+$/.test(str);
    }

    function getDadosCalculadoraCCL() {

        let altura = $("input[name='altura_ccl']").val();
        let idBase = $("select[name='base_ccl']").val();
        let idPeriodoLocacao = $("select[name='periodo_ccl']").val();
        let idTipoCalculadora = $("select[name='tipo_ccl']").val();
        let quantidadeTorre = $("input[name='torre_ccl']").val();
        let oportunidadeId = $("input[name='oportunidade_id']").val();

        if (isEmpty(altura) || isEmpty(quantidadeTorre)) {

            showToastDanger($('body'), 'Você precisa preencher a altura e a base');
            return;
        }

        if (limpaMoeda(quantidadeTorre) > 999999999 || limpaMoeda(altura) > 999999999) {

            showToastDanger($("body"), "Você precisa preencher a altura e a base corretamente, verifique os valores digitados");
            return;

        }

        if (!isIntString(limpaMoeda(altura))) {

            showToastDanger($("body"), "Você precisa preencher a altura com um número inteiro");
            return;
        }

        $(".js-produtos-ccl").html("");
        $("#overlayer-loading").addClass("show-overlayer");

        $.ajax({
            url: siteUrl("/painel/oportunidades/ccl/calculadora/resultado"),
            type: "get",
            data: {
                altura: altura,
                idBase: idBase,
                idPeriodoLocacao: idPeriodoLocacao,
                idTipoCalculadora: idTipoCalculadora,
                quantidadeTorre: quantidadeTorre,
                oportunidade_id: oportunidadeId,
            },
            success: function (res) {
                $("#overlayer-loading").removeClass("show-overlayer");
                if (!res.erro) {

                    let equipamentos = res.dados;
                    $(equipamentos).each(function (index, equipamento) {

                        let total = equipamento.quantidade * equipamento.valorUnitario;
                        let totalBrl = total.toLocaleString("pt-br", {
                            style: "currency",
                            currency: "BRL",
                        });
                        let valorUnitarioBrl = (equipamento.valorUnitario).toLocaleString("pt-br", {
                            style: "currency",
                            currency: "BRL",
                        });
                        let quantidadeBrl = (equipamento.quantidade).toLocaleString("pt-br");
                        /*($(equipamento.periodosLocacao).each(function(index, periodo) {
 
                             periodo.descricao;
                             periodo.dias;
                             periodo.id;
                             periodo.valor;
                             periodo.valorNegociado;
                         });*/
                        $(".js-produtos-ccl").append(`
                        <tr class="tr-produtos-ccl tr-produtos-ccl-selecionado">
                            <td>
                                ${equipamento.idEquipamentoNoCcl}
                                <input
                                    type="checkbox"
                                    checked=""checked
                                    name="js-checkbox-dados-produtos-ccl[]"
                                    class="invisible"
                                    data-codigo="${equipamento.idEquipamentoNoCcl}"
                                    data-descricao="${equipamento.nome}"
                                    data-quantidade="${equipamento.quantidade}"
                                    data-unitario="${equipamento.valorUnitario}"
                                    data-total="${total}"
                                >
                            </td>
                            <td>${equipamento.nome}</td>
                            <td>${quantidadeBrl}</td>
                            <td>${valorUnitarioBrl}</td>
                            <td>${totalBrl}</td>
                        </tr>`);
                    });
                } else {
                    showToastDanger(context, res.mensagem);
                }
            },
        });
    }

    $(context).on("click", ".tr-produtos-ccl", function () {

        if (!$(this).hasClass("tr-produtos-ccl-selecionado")) {

            $(this).removeClass("tr-produtos-ccl-nao-selecionado");
            $(this).addClass("tr-produtos-ccl-selecionado");
            $("input[name='js-checkbox-dados-produtos-ccl[]']", this).prop('checked', true);
        } else {

            $(this).addClass("tr-produtos-ccl-nao-selecionado");
            $(this).removeClass("tr-produtos-ccl-selecionado");
            $("input[name='js-checkbox-dados-produtos-ccl[]']", this).prop('checked', false);
        }

    });

    $(".js-salvar-produtos-ccl", context).on("click", function () {

        let oportunidade_id = $("input[name='oportunidade_id']").val();
        let periodo = $("select[name='periodo_ccl']").val();
        let dados = $("input[name='js-checkbox-dados-produtos-ccl[]']:checked");
        let dadosJson = [];

        if (dados.length < 1) {
            showToastDanger(context, 'Você precisa selecionar pelo menos um produto');
            return;
        }

        dados.toArray().forEach(function (element) {
            dadosJson.push($(element).data());
        });

        $.ajax({
            url: siteUrl(`/painel/oportunidades/ccl/calculadora/produtos`),
            type: "post",
            data: {
                oportunidade_id,
                periodo,
                dadosJson,
            },
            headers: {
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
            },
            success: function (res) {
                if (!res.erro) {
                    showToastSuccess(context, res.mensagem);
                    window.location.reload(true);
                } else {
                    showToastDanger(context, res.mensagem);
                    window.location.reload(true);
                }
            },
            error: function (err) {
                showToastDanger(
                    context,
                    "Ocorreu um erro ao tentar incluir os produtos"
                );
            },
        });
    });

    $(".js-calculadora-andaime-ccl", context).on("click", function () {
        getTiposCCL();
        getBasesCCL();
    });

    $(".js-botao-calcular-calculadora-andaime-ccl", context).on("click", function () {
        getDadosCalculadoraCCL();
    });

    $(".js-pegar-oportunidade", context).on('click', function () {

        const idOportunidade = $(".id-oportunidade").val();
        $.ajax({
            url: siteUrl(`/painel/oportunidades/pegar-oportunidade`),
            type: "PUT",
            data: {
                id_oportunidade: idOportunidade,
            },
            headers: {
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr(
                    "content"
                ),
            },
            success: function (res) {
                if (res.erro) {

                    showToastDanger(context, res.mensagem);
                    window.location.reload(true);
                } else {

                    showToastSuccess(context, res.mensagem);
                    window.location.reload(true);
                }
            },
            error: function (res) {

                showToastDanger(context, res.mensagem);
            },
        });

    });

    /* $('a[data-toggle="tab"]').on("shown.bs.tab", function (e) {
         let tabId = $(e.target).attr("href");
         let isAtivo = $(tabId).hasClass("active");
         let isChat = $(tabId).hasClass("js-tab-chat");
         if (isAtivo && isChat) {
 
             let configId = $(tabId).data("config-id");
             let ultimoProtocoloId = $(tabId).data("ultimo-protocolo-id");
             if (isEmpty(ultimoProtocoloId)) {
                 return
             }
 
             let isWhatsappWeb = Boolean($(tabId).data("whatsapp-web"));
             if (!isWhatsappWeb) {
                 return
             }
 
             $.ajax({
                 url: siteUrl(`/painel/oportunidades/sincronizar-whatsapp-web`),
                 type: "get",
                 data: {
                     config_id: configId,
                     ultimo_protocolo_id: ultimoProtocoloId,
                 },
                 success: function (res) {
                     if (res.erro) {
                         showToastDanger(context, res.mensagem);
                     }
                 },
                 error: function (err) {
                     console.log("err", err);
                     showToastDanger(context, "Ocorreu um erro inesperado");
                 },
             });
         }
     }); */

    $(".js-chat-mensagem", context).on("click", function () {
        const chatId = $(".js-show-popover-reagir", this).data("chat-id");
        $(".js-show-popover-reagir", context).not(`[data-chat-id=${chatId}]`).popover("hide");
        $(".js-reacoes-bloco", context).addClass("d-none");
        $(".js-emoji-list", context).removeClass("d-none");
    });

    $(".js-show-popover-reagir", context).on("click", function () {
        $(this, context).popover("show");
        const chatId = $(this).data("chat-id");

        const todasReacoes = $("#js-cache-todas-reacoes").html();
        $(`.js-bloco-reacoes-${chatId}.js-bloco-todas-reacoes`).html(todasReacoes);

        $(`.js-emoji-list-${chatId}`).unbind("click");
        $(`.js-emoji-list-${chatId}`).on("click", function () {
            $(`.js-bloco-reacoes-${chatId}.js-bloco-todas-reacoes`).removeClass("d-none");
            $(this).addClass("d-none");
        });

        $(`.js-bloco-reacoes-${chatId} .js-icone-reacao`).unbind("click");
        $(`.js-bloco-reacoes-${chatId} .js-icone-reacao`).on("click", function () {
            const emoji = $(this).html();
            const chatId = $(this).closest(".js-bloco-reacoes").prop("id");

            $(".js-show-popover-reagir", context).popover("hide");
            $.post({
                url: siteUrl(`/painel/chat/reagir/${chatId}`),
                type: "post",
                headers: {
                    "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
                },
                data: {
                    chatId,
                    emoji
                },
                success: function (response) {
                    if (response.error) {
                        showToastDanger(context, response.message)
                    } else {
                        $(`#js-mensagem-${chatId}`).addClass("com-reacao");
                        $(`#js-mensagem-${chatId} .js-mensagem-reacao`).removeClass("d-none");
                        $(`#js-mensagem-${chatId} .js-mensagem-reacao .js-reacao-user`).html(emoji);
                        showToastSuccess(context, response.message);
                    }
                },
                error: function (error) {
                    showToastDanger(context, 'Não foi possível enviar a reação, tente novamente.')
                }
            })
        });
    });

    $(".js-arquivo-ws-tentar-novamente", context).on("click", function () {
        const eventoId = $(this).data("evento-id");
        $.ajax({
            url: siteUrl(`/painel/whatsapp-websocket-eventos/processar-ajax/${eventoId}`),
            type: "get",
            success: function (res) {
                if (!res.erro) {
                    showToastSuccess(context, res.mensagem);
                } else {
                    showToastDanger(context, res.mensagem);
                }
            },
        });
    });

    $(".js-autocomplete-produto-oportunidades-chat", context).each(function () {
        const input = this;
        $(input)
            .autocomplete({
                source: siteUrl("/painel/produtos/busca/autocomplete?crm=true"),
                minLength: 2,
                select: function (event, ui) {
                    const modal = $(this)
                        .closest(".sidebar-chat")
                        .find("#addProdutosModal");
                    $(modal).modal();
                    $(modal)
                        .find("#addProdutosModalLabel", context)
                        .html("Adicionar produto");
                    $(modal)
                        .find("#produto_oportunidade_id", context)
                        .val("");
                    $(modal)
                        .find("#produto_id", context)
                        .val(ui.item.id ?? " - ");
                    $(modal)
                        .find("#sku-chat", context)
                        .html(ui.item.sku ?? " - ");
                    $(modal)
                        .find("#produto-nome", context)
                        .html(ui.item.nome ?? " - ");
                    let preco = 0;
                    if (ui.item.faixas_precos.length > 0) {
                        if (
                            $(modal)
                                .find("#bloco-faixa-chat", context)
                                .hasClass("d-none")
                        ) {
                            $(modal)
                                .find("#bloco-faixa-chat", context)
                                .removeClass("d-none");
                        }
                        if (
                            !$(modal)
                                .find("#bloco-preco-faixa-chat", context)
                                .hasClass("d-none")
                        ) {
                            $(modal)
                                .find("#bloco-preco-faixa-chat", context)
                                .addClass("d-none");
                        }
                        preco = ui.item.faixas_precos[0].preco;
                        $(modal)
                            .find("#faixa-chat", context)
                            .find("option")
                            .remove()
                            .end();
                        $.each(ui.item.faixas_precos, function (i, item) {
                            $(modal)
                                .find("#faixa-chat", context)
                                .append(
                                    $("<option>", {
                                        value: item.id,
                                        text: `${
                                            item.descricao
                                        } - R$ ${item.preco.toLocaleString(
                                            "pt-br",
                                            {
                                                style: "currency",
                                                currency: "BRL",
                                            }
                                        )}`,
                                    }).attr("data-id", item.preco)
                                );
                        });
                    } else {
                        if (
                            !$(modal)
                                .find("#bloco-faixa-chat", context)
                                .hasClass("d-none")
                        ) {
                            $(modal)
                                .find("#bloco-faixa-chat", context)
                                .addClass("d-none");
                        }
                        if (
                            $(modal)
                                .find("#bloco-preco-faixa-chat", context)
                                .hasClass("d-none")
                        ) {
                            $(modal)
                                .find("#bloco-preco-faixa-chat", context)
                                .removeClass("d-none");
                        }
                        preco = ui.item.preco;
                        $("#bloco-faixa-chat", context).attr(
                            "data-preco",
                            preco
                        );
                        $(modal)
                            .find("#preco-faixa-chat", context)
                            .val(
                                preco.toLocaleString("pt-br", {
                                    style: "currency",
                                    currency: "BRL",
                                })
                            );
                    }
                    $(modal)
                        .find("#bloco-faixa-chat", context)
                        .attr("data-preco", preco);
                    $(modal)
                        .find("#preco-chat", context)
                        .val(
                            preco.toLocaleString("pt-br", {
                                minimumFractionDigits: 2,
                            })
                        );
                    $(modal)
                        .find("#subtotal-chat", context)
                        .attr("data-id", preco);
                    $(modal)
                        .find("#subtotal-chat", context)
                        .html(
                            preco.toLocaleString("pt-br", {
                                style: "currency",
                                currency: "BRL",
                            })
                        );
                },
            })
            .autocomplete("instance")._renderItem = function (ul, item) {
            const url = siteUrl(`/painel/pedidos/produtosEdit/add/${item.id}`);
            let sku = "";
            if (item.sku && item.sku != null) {
                sku = ` - SKU: ${item.sku}`;
            }

            return $("<li class='lista-produto'>")
                .append(`#${item.id} - ${item.nome}${sku}`)
                .appendTo(ul);
        };
    });


    $(".js-atualiza-faixa-chat", context).on("change", function () {
        context = $(this).closest("form");
        const value = $(this).val();
        const option = $(this).find(`option`).filter(`[value=${value}]`);
        const preco = $(option).data("id");
        $("#bloco-faixa-chat", context).attr("data-preco", preco);
        $("#preco-chat", context).val(
            preco.toLocaleString("pt-br", {
                minimumFractionDigits: 2,
            })
        );
        calcularPrecosChat();
    });

    $(".js-input-produto-chat").on("input", function () {
        context = $(this).closest("form");
        calcularPrecosChat();
    });

    $(".js-input-produto-chat").on("change", function () {
        context = $(this).closest("form");
        let preco = $("#preco-chat", context).val();
        preco = limpaMoeda(preco);
        $("#preco-chat", context).val(
            preco.toLocaleString("pt-br", {
                minimumFractionDigits: 2,
            })
        );
    });

    function calcularPrecosChat() {
        const precoTabela = $("#bloco-faixa-chat", context).data("preco");
        let preco = $("#preco-chat", context).val();
        preco = limpaMoeda(preco);
        const quantidade = $("#qdt-chat").val();
        let desconto = precoTabela - preco;
        desconto = (desconto * 100) / precoTabela;
        desconto = desconto < 0 ? 0 : desconto;
        $("#desconto-chat", context).html(
            `${desconto.toLocaleString("pt-br", {
                minimumFractionDigits: 2,
            })}%`
        );
        $("#subtotal-chat", context).html(
            (preco * quantidade).toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
            })
        );
    }

    $(".js-cancelar-modal-produto-chat", context).on("click", function () {
        fecharModalChat($(this).closest("form"));
    });

    function fecharModalChat(context) {
        $("#produto_oportunidade_id", context).html("");
        $("#produto_id", context).html("");
        $("#sku-chat", context).html(" - ");
        $("#produto-nome", context).html(" - ");
        $("#qdt-chat", context).val(1);
        $("#preco-chat", context).val("0,00");
        $("#desconto-chat", context).html("0,00");
        $("#subtotal-chat", context).html("R$ 0,00");
        if (!$("#bloco-faixa-chat", context).hasClass("d-none")) {
            $("#bloco-faixa-chat", context).addClass("d-none");
        }
        if (!$("#bloco-preco-faixa-chat", context).hasClass("d-none")) {
            $("#bloco-preco-faixa-chat", context).addClass("d-none");
        }
    }

    $(".js-sidebar-chat-toggle", context).on("click", function () {
        const target = "#" + $(this).data("target");
        const targetTabela = "#" + $(this).data("load");
        const id = $(this).data("id");
        if ($(target).hasClass("d-none")) {
            carregarTebelaChat(targetTabela, id);
        }
        $(target).toggleClass("d-none");
        $(this).find("svg").toggleClass("d-none");
    });

    $(".js-tabela-produto-chat", context).on(
        "click",
        ".js-delete-item-ajax",
        function () {
            const modal = $(this).closest(".modal");
            const rota = $('input[name="rota"]', context).val();
            const oportunidadeId = $(modal).find('input[name="oportunidade"]').val();
            const prudutoTr = "#tr-item-" + $(modal).find('input[name="op_id"]').val();
            const targetTabela = "#" + $(modal)
                .closest(".sidebar-chat")
                .find(".js-tabela-produto-chat").attr('id');
            $(modal).modal();
            $(".modal-backdrop").remove();

            $.ajax({
                url: rota,
                type: "delete",
                headers: {
                    "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr(
                        "content"
                    ),
                },
                success: function (res) {
                    carregarTebelaChat(targetTabela, oportunidadeId);
                    $(prudutoTr).remove();
                    autalizarTotalTabela("tabela_produtos");
                },
                error: function (err) {
                    showToastDanger(modal);
                },
            });
        }
    );
    function carregarTebelaChat(targetTabela, id) {
        $(targetTabela).html(`
            <div class="mt-5 mb-4 pb-1" style="position: relative;">
                <div class="loading-circle"><div></div><div></div><div></div><div></div></div>
            </div>
        `);
        $.ajax({
            url: siteUrl(`/painel/oportunidade-tabela-produto-chat/${id}`),
            type: "get",
            success: function (res) {
                $(targetTabela).html(res);
            },
        });
    }

    $(".js-autocomplete-produto-oportunidades-ccl-chat", context).each(function () {
        const input = this;
        $(input)
            .autocomplete({
                source: siteUrl(
                    "/painel/produtos/busca/autocomplete?crm=true"
                ),
                minLength: 2,
                select: function (event, ui) {
                    const modal =
                    $(this).closest(".sidebar-chat")
                        .find("#addProdutosModal");
                    $(modal).modal();

                    $(modal)
                        .find("#addProdutosModalLabel", context)
                        .html("Adicionar produto");

                    $("#overlayer-loading").removeClass("show-overlayer");
                    $(modal).find("#produto_id", context).val(ui.item.id ?? " - ");
                    $(modal)
                        .find("#sku-chat", context)
                        .html(ui.item.sku ?? " - ");
                    $(modal)
                        .find("#produto-nome", context)
                        .html(ui.item.nome ?? " - ");
                    let preco = 0;
                    if (ui.item.faixas_precos.length > 0) {
                        if($(modal)
                                .find("#bloco-faixa-chat", context)
                                .hasClass("d-none")
                            ) {
                            $(modal)
                                .find("#bloco-faixa-chat", context)
                                .removeClass("d-none")
                        }
                        if(!$(modal)
                                .find("#bloco-preco-faixa-chat", context)
                                .hasClass("d-none")
                            ) {
                            $(modal)
                                .find("#bloco-preco-faixa-chat", context)
                                .addClass("d-none")
                        }
                        preco = ui.item.faixas_precos[0].preco;
                        $(modal)
                            .find("#faixa-chat", context)
                            .find("option")
                            .remove()
                            .end();
                        $.each(ui.item.faixas_precos, function (i, item) {
                            $(modal)
                                .find("#faixa-chat", context)
                                .append(
                                    $("<option>", {
                                        value: item.id,
                                        text: `${
                                            item.descricao
                                        } - R$ ${item.preco.toLocaleString(
                                            "pt-br",
                                            {
                                                style: "currency",
                                                currency: "BRL",
                                            }
                                        )}`,
                                    }).attr("data-id", item.preco)
                                );
                        });
                    } else {
                        if (
                            !$(modal)
                                .find("#bloco-faixa-chat", context)
                                .hasClass("d-none")
                        ) {
                            $(modal)
                                .find("#bloco-faixa-chat", context)
                                .addClass("d-none");
                        }
                        if (
                            $(modal)
                                .find("#bloco-preco-faixa-chat", context)
                                .hasClass("d-none")
                        ) {
                            $(modal)
                                .find("#bloco-preco-faixa-chat", context)
                                .removeClass("d-none");
                        }
                        preco = ui.item.preco;
                        $(modal)
                            .find("#bloco-faixa-chat", context)
                            .attr("data-preco", preco);
                        $(modal)
                            .find("#preco-faixa-chat", context)
                            .val(
                                preco.toLocaleString("pt-br", {
                                    style: "currency",
                                    currency: "BRL",
                                })
                            );
                    }
                    $(modal)
                        .find("#bloco-faixa-chat", context)
                        .attr("data-preco", preco);
                    $(modal)
                        .find("#preco-chat", context)
                        .val(
                            preco.toLocaleString("pt-br", {
                                minimumFractionDigits: 2,
                            })
                        );
                    $(modal)
                        .find("#subtotal-chat", context)
                        .attr("data-id", preco);
                    $(modal)
                        .find("#subtotal-chat", context)
                        .html(
                            preco.toLocaleString("pt-br", {
                                style: "currency",
                                currency: "BRL",
                            })
                        );
                },
            })
            .autocomplete("instance")._renderItem = function (ul, item) {
            const url = siteUrl(`/painel/pedidos/produtosEdit/add/${item.id}`);
            let sku = "";
            if (item.sku && item.sku != null) {
                sku = ` - SKU: ${item.sku}`;
            }

            return $("<li class='lista-produto'>")
                .append(`#${item.id} - ${item.nome}${sku}`)
                .appendTo(ul);
        };
    });

    $(".js-chat-adcionar-produto-ccl").on("click", function () {
        const input = this;
        const form = $(this).closest("form");
        const itemId = $(form).find("#produto_oportunidade_id").val();
        const produtoId = $(form).find("#produto_id").val();
        const oportunidadeId = $(form).find("#oportunidade_id").val();
        const faixaId = $(form).find("#faixa-chat").val();
        const preco = $(form).find("#preco-chat").val();
        const quantidade = $(form).find("#qdt-chat").val();

        if (itemId) {
            atualizarProdutoChatCCL(itemId, faixaId, preco, quantidade);
        } else {
            incluirProdutoChatCCL(
                produtoId,
                oportunidadeId,
                faixaId,
                preco,
                quantidade,
                input
            );
        }

    });

    function incluirProdutoChatCCL(
        produtoId,
        oportunidadeId,
        faixaId,
        preco,
        quantidade,
        input
    ) {

        $.ajax({
            url: siteUrl("/painel/oportunidades/ccl/produto"),
            type: "post",
            headers: {
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
            },
            data: {
                produto_id: produtoId,
                oportunidade_id: oportunidadeId,
                faixa_id: faixaId,
                preco: preco,
                quantidade: quantidade,
            },

            success: function (res) {
                $("#overlayer-loading").removeClass("show-overlayer");
                context = $(input).closest(".sidebar-chat");
                if (res.error) {
                    showToastDanger(context, res.message);
                } else {
                    showToastSuccess(context);
                    const trChat = res.htmlChat;
                    const tr = res.html;
                    const item_id = res.itemId;
                    $(".js-tabela-produtos-chat tbody", context).append(trChat);
                    Eloca.load($(`#tr-item-chat-${item_id}`));
                    autalizarTotalTabela(`#tr-item-chat-${item_id}`);

                    $(".js-tabela-produtos tbody").append(tr);
                    Eloca.load($(`#tr-item-${item_id}`));
                    autalizarTotalTabela(`#tr-item-${item_id}`);

                    if ($(".js-racerregar-modelo").hasClass("d-none")) {
                        $(".js-racerregar-modelo").removeClass("d-none");
                    }
                    fecharModalChat($(this).closest("form"));
                }
            },
            error: function (err) {
                $("#overlayer-loading").removeClass("show-overlayer");
                 showToastDanger(context, err.message);
            },
        });
    }

    function atualizarProdutoChatCCL(
        itemId,
        faixaId,
        preco,
        quantidade
    ) {
        $.ajax({
            url: siteUrl("/painel/oportunidades/ccl/produto"),
            type: "put",
            headers: {
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
            },
            data: {
                item_id: itemId,
                faixa_id: faixaId,
                preco: preco,
                quantidade: quantidade,
            },
            success: function (res) {
                $("#overlayer-loading").removeClass("show-overlayer");
                if (res.error) {
                    showToastDanger(context, res.message);
                } else {
                    showToastSuccess(context);
                    if (quantidade > 0) {
                        const novaTr = res.htmlChat;
                        const tr = $(context)
                            .find(".js-tabela-produtos-chat")
                            .find(`#tr-item-chat-${item_id}`);
                        $(tr, context).replaceWith(novaTr);
                        Eloca.load($(`#tr-item-chat-${item_id}`));
                    } else {
                        $(tr).remove();
                    }
                    autalizarTotalTabela(`#tr-item-chat-${item_id}`);
                    atualizarDadosOportunidade();
                }
            },
            error: function (err) {
                $("#overlayer-loading").removeClass("show-overlayer");
                showToastDanger(context, err.message);
            },
        });
    }

    $(".js-tabela-produto-chat", context).on(
        "click",
        ".js-carregar-produto-chat",
        function () {
            const produtoChat = this;
            $("#overlayer-loading").addClass("show-overlayer");
            const proOpId = $(this)
                .closest(".js-tr-produtos")
                .find(".js-item-id")
                .val();
            $.ajax({
                url: siteUrl(`/painel/oportunidade-produto/${proOpId}`),
                type: "GET",
                headers: {
                    "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr(
                        "content"
                    ),
                },
                success: function (res) {
                    $("#overlayer-loading").removeClass("show-overlayer");
                    const modal = $(produtoChat)
                        .closest(".sidebar-chat")
                        .find("#addProdutosModal");

                    $(modal).modal();

                    $(modal)
                        .find("#addProdutosModalLabel", context)
                        .html("Atualizar produto");

                    $(modal)
                        .find("#produto_oportunidade_id", context)
                        .val(res.id ?? " - ");
                    $(modal)
                        .find("#sku-chat", context)
                        .html(res.produto.sku ?? " - ");
                    $(modal)
                        .find("#produto_id", context)
                        .val(res.produto_id ?? " - ");
                    $(modal)
                        .find("#produto-nome", context)
                        .html(res.produto.nome ?? " - ");
                    let preco = 0;
                    if (res.faixa_preco) {
                        if (
                            $(modal)
                                .find("#bloco-faixa-chat", context)
                                .hasClass("d-none")
                        ) {
                            $(modal)
                                .find("#bloco-faixa-chat", context)
                                .removeClass("d-none");
                        }
                        if (
                            !$(modal)
                                .find("#bloco-preco-faixa-chat", context)
                                .hasClass("d-none")
                        ) {
                            $(modal)
                                .find("#bloco-preco-faixa-chat", context)
                                .addClass("d-none");
                        }
                        preco = parseFloat(res.faixa_preco.preco);
                        $(modal)
                            .find("#faixa-chat", context)
                            .find("option")
                            .remove()
                            .end();
                        $.each(res.produto.faixas_precos, function (i, item) {
                            let precoFaixa = parseFloat(item.preco);
                            $(modal)
                                .find("#faixa-chat", context)
                                .append(
                                    $("<option>", {
                                        value: item.id,
                                        text: `${
                                            item.descricao
                                        } - R$ ${precoFaixa.toLocaleString(
                                            "pt-br",
                                            {
                                                style: "currency",
                                                currency: "BRL",
                                            }
                                        )}`,
                                    }).attr(
                                        "data-id",
                                        precoFaixa.toLocaleString("pt-br", {
                                            minimumFractionDigits: 2,
                                        })
                                    )
                                );
                        });
                    } else {
                        if (
                            !$(modal)
                                .find("#bloco-faixa-chat", context)
                                .hasClass("d-none")
                        ) {
                            $(modal)
                                .find("#bloco-faixa-chat", context)
                                .addClass("d-none");
                        }
                        if (
                            $(modal)
                                .find("#bloco-preco-faixa-chat", context)
                                .hasClass("d-none")
                        ) {
                            $(modal)
                                .find("#bloco-preco-faixa-chat", context)
                                .removeClass("d-none");
                        }
                        preco = parseFloat(res.preco);
                        $(modal)
                            .find("#bloco-faixa-chat", context)
                            .attr("data-preco", preco);
                        $(modal)
                            .find("#preco-faixa-chat", context)
                            .val(
                                preco.toLocaleString("pt-br", {
                                    style: "currency",
                                    currency: "BRL",
                                })
                            );
                    }

                    $(modal).find("#qdt-chat", context).val(res.quantidade);
                    $(modal)
                        .find("#bloco-faixa-chat", context)
                        .attr("data-preco", preco);
                    $(modal)
                        .find("#preco-chat", context)
                        .val(
                            preco.toLocaleString("pt-br", {
                                minimumFractionDigits: 2,
                            })
                        );
                    $(modal)
                        .find("#subtotal-chat", context)
                        .attr("data-id", preco);
                    $(modal)
                        .find("#subtotal-chat", context)
                        .html(
                            preco.toLocaleString("pt-br", {
                                style: "currency",
                                currency: "BRL",
                            })
                        );
                },
                error: function (err) {
                    $("#overlayer-loading").removeClass("show-overlayer");
                    showToastDanger(context);
                },
            });
        }
    );

    $(".js-chat-adcionar-produto").on("click", function () {
        const input = this;
        const form = $(this).closest("form");
        const itemId = $(form).find("#produto_oportunidade_id").val();
        const produtoId = $(form).find("#produto_id").val();
        const oportunidadeId = $(form).find("#oportunidade_id").val();
        const faixaId = $(form).find("#faixa-chat").val();
        const preco = $(form).find("#preco-chat").val();
        const quantidade = $(form).find("#qdt-chat").val();

        if (itemId) {
            atualizarProdutoChat(itemId, faixaId, preco, quantidade, input);
        } else {
            incluirProdutoChat(
                produtoId,
                oportunidadeId,
                faixaId,
                preco,
                quantidade,
                input
            );
        }

    });


    function incluirProdutoChat(
        produtoId,
        oportunidadeId,
        faixaId,
        preco,
        quantidade,
        input
    ) {
        $.ajax({
            url: siteUrl("/painel/oportunidades/adicionar-produto"),
            type: "post",
            headers: {
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
            },
            data: {
                produto_id: produtoId,
                oportunidade_id: oportunidadeId,
                faixa_id: faixaId,
                preco: preco,
                quantidade: quantidade,
            },
            success: function (res) {
                $("#overlayer-loading").removeClass("show-overlayer");
                context = $(input).closest(".sidebar-chat");
                if (res.error) {
                    showToastDanger(context, res.message);
                } else {
                    showToastSuccess(context);
                    const trChat = res.htmlChat;
                    const tr = res.html;
                    const item_id = res.itemId;
                    $(".js-tabela-produtos-chat tbody", context).append(trChat);
                    Eloca.load($(`#tr-item-chat-${item_id}`));
                    autalizarTotalTabela(`#tr-item-chat-${item_id}`);

                    $(".js-tabela-produtos tbody").append(tr);
                    Eloca.load($(`#tr-item-${item_id}`));
                    autalizarTotalTabela(`#tr-item-${item_id}`);

                    if ($(".js-racerregar-modelo").hasClass("d-none")) {
                        $(".js-racerregar-modelo").removeClass("d-none");
                    }
                    fecharModalChat($(input).closest("form"));
                }
            },
            error: function (err) {
                $("#overlayer-loading").removeClass("show-overlayer");
                showToastDanger(context);
            },
        });
    }


    function atualizarProdutoChat(item_id, faixa_id, preco, quantidade, input) {
        context = $(input).closest(".sidebar-chat");
        $.ajax({
            url: siteUrl("/painel/oportunidades/atualizar-produto"),
            type: "put",
            headers: {
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
            },
            data: { item_id, faixa_id, preco, quantidade },
            success: function (res) {
                $("#overlayer-loading").removeClass("show-overlayer");
                if (res.error) {
                    showToastDanger(context, res.message);
                } else {
                    showToastSuccess(context);
                    if (quantidade > 0) {
                        const novaTr = res.htmlChat;
                        const tr = $(context)
                            .find(".js-tabela-produtos-chat")
                            .find(`#tr-item-chat-${item_id}`);
                        $(tr, context).replaceWith(novaTr);
                        Eloca.load($(`#tr-item-chat-${item_id}`));
                    } else {
                        $(tr).remove();
                    }
                    autalizarTotalTabela(`#tr-item-chat-${item_id}`);
                }
            },
            error: function (err) {
                $("#overlayer-loading").removeClass("show-overlayer");
                showToastDanger(context);
            },
        });
    }

    $(".js-container-chat").on(
        "click",
        ".js-add-produto-mensagem",
        function () {
            const sidebar = $(this)
                .closest(".js-tab-chat")
                .find(".js-bloco-chat");
            if ($(sidebar).hasClass("d-none")) {
                $(this)
                    .closest(".js-tab-chat")
                    .find(".js-sidebar-chat-toggle")
                    .trigger("click");
                $(sidebar).removeClass("d-none");
            }
            const produtoId = $(this).data("id");
            $("#overlayer-loading").addClass("show-overlayer");
            $.ajax({
                url: siteUrl(`/painel/produto-carregar/${produtoId}`),
                type: "get",
                headers: {
                    "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr(
                        "content"
                    ),
                },
                success: function (res) {
                    $("#overlayer-loading").removeClass("show-overlayer");
                    const modal = $(sidebar)
                        .find(".sidebar-chat")
                        .find("#addProdutosModal");
                    $(modal).modal();

                    $(modal)
                        .find("#addProdutosModalLabel", context)
                        .html("Adicionar produto");
                    $(modal)
                        .find("#produto_id", context)
                        .val(res.produto.id ?? " - ");
                    $(modal)
                        .find("#sku-chat", context)
                        .html(res.produto.sku ?? " - ");
                    $(modal)
                        .find("#produto-nome", context)
                        .html(res.produto.nome ?? " - ");
                    let preco = 0;
                    if (res.produto.faixas_precos.length > 0) {
                        if (
                            $(modal)
                                .find("#bloco-faixa-chat", context)
                                .hasClass("d-none")
                        ) {
                            $(modal)
                                .find("#bloco-faixa-chat", context)
                                .removeClass("d-none");
                        }
                        if (
                            !$(modal)
                                .find("#bloco-preco-faixa-chat", context)
                                .hasClass("d-none")
                        ) {
                            $(modal)
                                .find("#bloco-preco-faixa-chat", context)
                                .addClass("d-none");
                        }
                        preco = parseFloat(res.produto.faixas_precos[0].preco);
                        $(modal)
                            .find("#faixa-chat", context)
                            .find("option")
                            .remove()
                            .end();
                        $.each(res.produto.faixas_precos, function (i, item) {
                            const precoItem = parseFloat(item.preco);
                            $(modal)
                                .find("#faixa-chat", context)
                                .append(
                                    $("<option>", {
                                        value: item.id,
                                        text: `${
                                            item.descricao
                                        } - R$ ${precoItem.toLocaleString(
                                            "pt-br",
                                            {
                                                style: "currency",
                                                currency: "BRL",
                                            }
                                        )}`,
                                    }).attr("data-id", precoItem)
                                );
                        });
                    } else {
                        if (
                            !$(modal)
                                .find("#bloco-faixa-chat", context)
                                .hasClass("d-none")
                        ) {
                            $(modal)
                                .find("#bloco-faixa-chat", context)
                                .addClass("d-none");
                        }
                        if (
                            $(modal)
                                .find("#bloco-preco-faixa-chat", context)
                                .hasClass("d-none")
                        ) {
                            $(modal)
                                .find("#bloco-preco-faixa-chat", context)
                                .removeClass("d-none");
                        }
                        preco = parseFloat(res.produto.preco);
                        $(modal)
                            .find("#bloco-faixa-chat", context)
                            .attr("data-preco", preco);
                        $(modal)
                            .find("#preco-faixa-chat", context)
                            .val(
                                preco.toLocaleString("pt-br", {
                                    style: "currency",
                                    currency: "BRL",
                                })
                            );
                    }
                    $(modal)
                        .find("#bloco-faixa-chat", context)
                        .attr("data-preco", preco);
                    $(modal)
                        .find("#preco-chat", context)
                        .val(
                            preco.toLocaleString("pt-br", {
                                minimumFractionDigits: 2,
                            })
                        );
                    $(modal)
                        .find("#subtotal-chat", context)
                        .attr("data-id", preco);
                    $(modal)
                        .find("#subtotal-chat", context)
                        .html(
                            preco.toLocaleString("pt-br", {
                                style: "currency",
                                currency: "BRL",
                            })
                        );
                },
                error: function (err) {
                    $("#overlayer-loading").removeClass("show-overlayer");
                    showToastDanger(context);
                },
            });
        }
    );
};
